<template>
  <div class="planbox">
    <div class="img_header"><img :src="encnimg[num].imgurl" alt="" /></div>
    <div class="tit">
      {{ planddata[num].title }}
    </div>
    <div class="imgbox">
      <img src="../../img/D户型.png" alt="" />
    </div>
    <div class="w100">
      <div class="but">
        <div class="left"></div>
        <div class="right" @click="location">
          {{ planddata[num].button }}
        </div>
      </div>
    </div>
    <div class="Oul">
      <img src="../../img/圆.png" alt="" />
      <div>{{ planddata[num].oul1 }}</div>
    </div>
    <div class="placeholdera">24.22㎡—24.65㎡</div>
    <div class="Oul">
      <img src="../../img/圆.png" alt="" />
      <div>{{ planddata[num].oul2 }}</div>
    </div>
    <div class="placeholdera" v-html="planddata[num].oul2data"></div>
    <div class="footerimg">
      <img src="../../img/D户型1.png" alt="" />
    </div>
    <div class="footerimg">
      <img src="../../img/D户型2.png" alt="" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["num"]),
  },
  methods: {
    location() {
      window.location.href = "https://vr.om.cn/view/96466";
    },
  },
  data() {
    return {
      encnimg: [
        {
          imgurl: require("../../img/headerimg.png"),
        },
        {
          imgurl: require("../../img/headerenimg.png"),
        },
      ],
      planddata: {
        0: {
          title: "D户型品鉴",
          button: "全景看房",
          oul1: "户型面积",
          oul1data: "24.22㎡——24.65㎡",
          oul2: "智能住宅",
          oul2data: `智能客控系统<br>智慧/舒适/安全/节能/随心所欲`,
        },
        1: {
          title: "Type D",
          button: "VR Condo",
          oul1: "Type D",
          oul1data: "24.22㎡——24.65㎡",
          oul2: "Multiple Types",
          oul2data: `Multiple types tailored to attend all needs`,
        },
      },
    };
  },
};
</script>

<style lang="less" scoped>
.planbox {
  width: 8rem;
  height: 100%;
  margin: 0 auto;
  // background-color: antiquewhite;
}
.footerimg {
  width: 100%;
  height: 4.7rem;

  display: flex;
  margin-bottom: 0.15rem;
}
.footerimg img {
  width: 100%;
  margin: 0 auto;
}
.placeholdera {
  height: 0.42rem;
  font-size: 0.3rem;
  line-height: none !important;
  margin: 0.2rem 0 0.4rem 0.3rem;
}

.Oul {
  display: flex;
  align-items: center;
  font-size: 0.26rem;
}
.Oul div {
  margin-left: 0.06rem;
  color: #a08e75;
  font-weight: 500;
}
.Oul img {
  width: 0.15rem;
  padding-left: 0.3rem;
}
.plan img {
  width: 100%;
}
.h122 {
  height: 1.22rem;
  display: flex;
}
.h122 img {
  width: 2.3rem;
  margin: auto;
}
.zhanwei {
  width: 90%;
  border-top: 0.01rem solid #a08e75;
  margin: auto;
  margin-bottom: 0.3rem;
}
.tit {
  font-size: 0.3rem;
  text-align: center;
  font-weight: 700;
  margin: 0.4rem auto;
}
.imgbox {
  width: 100%;
  position: relative;
}
.imgbox > img {
  width: 70%;
  margin: 0 auto;
  display: block;
}
.directionbox {
  position: absolute;
}
.w100 {
  width: 100%;
}
.w100 .but {
  width: 2.4rem;
  height: 0.5rem;
  border-radius: 0.15rem;
  background-size: 100% 100%;
  margin: auto;
  margin-top: 0.3rem;
  display: flex;
  margin-bottom: 0.4rem;
  background-image: url("../../img/360°_icon.png");
}
.but .left {
  width: 0.38rem;
}
.but .right {
  // display: flex;
  // align-items: center;
  flex: 1;
  font-size: 0.22rem;
  line-height: 0.5rem;
  color: white;
  text-align: center;
  text-indent: 0.1rem;
}
.img_header {
  height: 0.8rem;
  width: 4.26rem;
  margin: 0 auto;
  img {
    width: 100%;
  }
}
</style>